import * as React from 'react'
import styled, { css } from 'styled-components'
import * as Prices from 'theme/atoms/Price'
import * as t from './types'
import theme from 'theme'
import useTranslations from 'hooks/useTranslations'
import { isItemChannelRestricted } from 'hooks/account/utils'
import useAccount from 'hooks/account/useAccount'
import isPoplarSku from 'utils/poplarSkuMap'
import config from 'config'

export default function Flags(props: t.Props) {
  /**  @firescoutMockVar molecules-Flags.t */
  const t = useTranslations<'molecules-Flags'>()
  const reduced: any[] = []
  const labels: any[] = []
  const channel = useAccount().channel
  const poplarActive = isPoplarSku(props.product.sku)

  const flagWhitelist = ['new', 'discount', 'sale', 'series'] as t.Flags[]
  const restricted = React.useMemo(
    () => isItemChannelRestricted(props.product, channel),
    [channel, props.product]
  )

  if (!props.product.flags.length && !restricted && !poplarActive) return null

  props.product.flags.map((flag) => {
    if (flag === 'sale') {
      if (
        Prices.reduction(
          props.product.prices.piecePriceNet,
          props.product.prices.piecePseudoPriceNet
        ) !== ''
      ) {
        reduced.push(
          <span>
            <Prices.PriceReduction
              prices={props.product.prices}
              logic="piece"
            />
          </span>
        )
      }
    } else {
      if (flagWhitelist.includes(flag as t.Flags)) {
        if (t(flag as t.Flags)) {
          labels.push(
            <span data-cy-state="show-dynamic-flag">{t(flag as t.Flags)}</span>
          )
        }
      }
    }
  })

  if (
    labels.length <= 0 &&
    reduced.length <= 0 &&
    !restricted &&
    !poplarActive &&
    !props.product.flags.includes('second_choice')
  )
    return null

  return (
    <Wrapper
      data-cy-ctx="molecules/Flags"
      className={'Flags ' + props.type}
      $type={props.type}
      $showOnlyDiscount={props.showOnlyDiscount}
      $wdv-2487-3D-Poplar={config.abTests['wdv-2487-3D-Poplar'] || false}
    >
      {props.product.flags.includes('second_choice') && (
        <div className="second-choice" data-cy-state="show-second-choice-flag">
          <span>{t('secondChoice')}</span>
        </div>
      )}
      {restricted && (
        <div
          className="channelRestriction"
          data-cy-state="show-channel-restriction-flag"
        >
          <span>{t(channel === 'b2b' ? 'onlyB2C' : 'onlyB2B')}</span>
        </div>
      )}
      {reduced.length > 0 && (
        <div
          className="reduced"
          data-cy-state="show-percentage-reduction-flags"
        >
          {reduced.map((label, i) => (
            <span key={i}>{label}</span>
          ))}
        </div>
      )}

      {!props.showOnlyDiscount && labels.length > 0 && (
        <div className="labels" data-cy-state="show-only-discount-flag">
          {labels.map((label, i) => (
            <span key={i}>
              {i > 0 && ' / '}
              {label}
            </span>
          ))}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<t.WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing('xxs')};

  > .channelRestriction {
    color: ${theme.colors.b0};
    background-color: ${theme.colors.shade.b6};

    ${theme.rounding('s')}
    border-bottom-left-radius: 0;
    ${theme._ty([13, 0, 24], theme.fontSpecial, '700')}

    ${(p) =>
      p.$type === 'pdp' &&
      css`
        padding: ${theme.spacing('xxs')} ${theme.spacing('m')};
      `}
    ${(p) =>
      p.$type === 'widget' &&
      css`
        padding: 0 ${theme.spacing('xs')};
      `}
  }

  > .second-choice {
    color: ${theme.colors.white};
    background-color: ${theme.colors.green_50};

    ${theme.rounding('s')}
    border-bottom-left-radius: 0;
    ${theme._ty([13, 0, 24], theme.fontSpecial, '700')}

    ${(p) =>
      p.$type === 'pdp' &&
      css`
        padding: ${theme.spacing('xxs')} ${theme.spacing('m')};
      `}
    ${(p) =>
      p.$type === 'widget' &&
      css`
        padding: 0 ${theme.spacing('xs')};
      `}
  }

  > .reduced {
    background-color: ${theme.colors.accent.red};
    color: ${theme.colors.white};
    ${theme.rounding('s')};
    ${(p) =>
      p.$showOnlyDiscount
        ? 'border-bottom-left-radius: 1;'
        : 'border-bottom-left-radius: 0;'}
    ${theme._ty([13, 0, 24], theme.fontSpecial, '700')}

    ${(p) =>
      p.$type === 'pdp' &&
      css`
        padding: 0.3125rem 1.25rem 0.3125rem 1.25rem;
      `}
    ${(p) =>
      p.$type === 'widget' &&
      css`
        padding: 0 ${theme.spacing('s')};
      `}
  }

  > .labels {
    /* margin-left: ${theme.spacing('xxs')}; */
    background-color: ${theme.colors.shade.primaryUltraBright};
    ${theme.rounding('s')};
    border-bottom-left-radius: 0;
    ${theme._ty([13, 0, 24], theme.fontSpecial, '700')}

    ${(p) =>
      p.$type === 'pdp' &&
      css`
        padding: 0.3125rem 1.25rem 0.3125rem 1.25rem;
      `}
    ${(p) =>
      p.$type === 'widget' &&
      css`
        padding: 0 ${theme.spacing('s')};
      `}
  }

  > .model-3d {
    display: flex;
    align-items: center;
    color: ${theme.colors.b0};
    background-color: ${theme.colors.shade.primaryUltraBright};
    ${theme._ty([13, 0, 24], theme.fontSpecial, '700')}
    > svg {
      margin-right: 5px;
    }
    ${(props) =>
      props.$type === 'pdp' &&
      css`
        padding: 5px 20px;
      `}
    ${(props) =>
      props.$type === 'widget' &&
      css`
        padding: 0px 20px;
      `}
  }
`
