import store from 'store'
import * as a from './actions'
import { addRule } from 'redux-ruleset'
import * as at from './const'

declare global {
  interface Window {
    __module_ui_opted_in: () => void
  }
}

if (typeof window !== 'undefined') {
  window.__module_ui_opted_in = function () {
    store.dispatch(a.setOptIn())
  }
}

//WDV-3313 NewsletterIntentLayer
// show intent layer after 5 pageviews
// set sessionStorage item to prevent showing the intent layer again
addRule({
  id: 'ui/IntentLayer',
  target: at.INCREMENT_LOCATION_CHANGE_COUNTER,
  output: at.SET_INTENT_VISIBLE,
  condition: (_, { getState }) => {
    const state = getState()
    const counter = state.ui.locationChangeCounter
    const isExecuted = sessionStorage.getItem('intentLayerExecuted') || false

    return counter === 6 && !state.ui.registerNewsletterNL && !isExecuted
  },
  delay: 100,
  addOnce: true,
  consequence: () => {
    store.dispatch({
      type: at.SET_INTENT_VISIBLE,
      payload: { visible: true, source: '5_pageviews' }
    })
  }
})
