import * as dy from 'utils/dy-hybrid'
import { addRule } from 'redux-ruleset'
import config from 'config'
import createHash from 'utils/createHash'
import { LOCATION_CHANGED } from 'modules/navigation/const'
import getPageInfo from 'utils/getPageInfo'
import {
  listingPageContext,
  LISTING_PAGE_CONTEXT
} from 'theme/templates/Category/events'
import { FETCH_SUCCESS as PDP_FETCH_SUCCESS } from 'modules/productDetail/const'
import { getDisplayVariant } from 'modules/productDetail/selectors'
import { cartPageView } from 'theme/templates/Cart/event'
import { PromotionItem } from 'hooks/account/types'
import { ChannelType } from 'utils/dy-hybrid/types'
import { SET_SEARCH_VALUE } from 'modules/ui/const'
import { FETCH_SUCCESS as SERIES_FETCH_SUCCESS } from 'modules/seriesDetail/const'
import manager from 'utils/dy-hybrid/manager'
import * as filterEvents from 'theme/molecules/ListingFilter/FilterDrawer/events'

// const numericFilterValues = [
//   'price',
//   'rating',
//   'stock',
//   'width',
//   'height',
//   'length',
//   'weight',
//   'diameter',
// ]

addRule({
  id: 'dy/LOGIN',
  target: `LOGIN`,
  output: '#dy-event',
  consequence: async (action) => {
    dy.reportEventWithConsent({
      name: 'Login',
      properties: {
        dyType: 'login-v1',
        hashedEmail: await createHash(action.context.email)
      }
    })
  }
})
addRule({
  id: 'dy/FILTER_VALUE_CLICK',
  target: filterEvents.FILTER_VALUE_CLICK,
  output: '#dy-event',
  consequence: (action) => {
    const { filterName, filterValue } = action.payload
    if (filterName === 'BRAND')
      dy.reportEventWithConsent({
        name: 'Filter Items',
        properties: {
          dyType: 'filter-items-v1',
          filterType: filterName[0] + filterName.slice(1).toLowerCase(),
          filterStringValue: filterValue
        }
      })
    // if (numericFilterValues.includes(action.payload.filterName)) {
    //   dy.reportEventWithConsent({
    //     name: 'Filter Items',
    //     properties: {
    //       dyType: 'filter-items-v1',
    //       filterType: action.payload.filterName,
    //       filterNumericValue: action.payload.filterValue,
    //     },
    //   })
    // } else {
    //   dy.reportEventWithConsent({
    //     name: 'Filter Items',
    //     properties: {
    //       dyType: 'filter-items-v1',
    //       filterType: action.payload.filterName,
    //       filterStringValue: action.payload.filterValue,
    //     },
    //   })
    // }
  }
})

addRule({
  id: 'dl/SET_FILTER_VALUE_LISTING',
  target: 'listingCategoryTree/tagCategoryClick',
  output: '#dy-event',
  consequence: async (action) => {
    dy.reportPageVisit({
      type: 'CATEGORY',
      data: action.payload.dyPathId.split('|'),
      location: window.location.href,
      locale: config.i18n.locale.replace('-', '_')
    })
  }
})

addRule({
  id: 'dy/HASHEDMAIL',
  target: [`REGISTER`, LOCATION_CHANGED],
  output: '#dy-event',
  consequence: async (action) => {
    if (action.type === 'REGISTER') {
      if (!action.context.isGuest) {
        dy.reportEventWithConsent({
          name: 'Sign Up',
          properties: {
            dyType: 'signup-v1',
            hashedEmail: await createHash(action.context.email)
          }
        })
      }
      if (!action.context.isGuest && action.context.loggedIn) {
        dy.reportEventWithConsent({
          name: 'Login',
          properties: {
            dyType: 'login-v1',
            hashedEmail: await createHash(action.context.email)
          }
        })
      } else {
        if (action.context.email.length > 5)
          dy.reportEventWithConsent({
            name: 'Identify User',
            properties: {
              dyType: 'identify-v1',
              hashedEmail: await createHash(action.context.email)
            }
          })
      }
    } else if (
      action.type === LOCATION_CHANGED &&
      action.payload.href.search('&sc_ehdy=') > 0
    ) {
      const hashRegex = /^[0-9a-fA-F]{32,64}$/
      const regex = /^[^#&?]+/
      const sc_ehdy = action.payload.href.split('&sc_ehdy=')[1]
      const hashedEmail = sc_ehdy.match(regex)?.[0] || ''

      if (hashRegex.test(hashedEmail)) {
        dy.reportEventWithConsent({
          name: 'Identify User',
          properties: {
            dyType: 'identify-v1',
            hashedEmail: hashedEmail
          }
        })
      }
    } else return
  }
})

addRule({
  id: 'dy/NEWSLETTER',
  target: `NEWSLETTER_REGISTER`,
  output: '#dy-event',
  consequence: async (action) => {
    dy.reportEventWithConsent({
      name: 'Newsletter Subscription',
      properties: {
        dyType: 'newsletter-subscription-v1',
        hashedEmail: await createHash(action.email)
      }
    })
  }
})

addRule({
  id: 'dy/PURCHASE',
  target: `checkout/PURCHASE`,
  output: '#dy-event',
  consequence: (action) => {
    const order = action.meta.order
    const netPreis = (order.totalPrice - order.tax).toFixed(2)
    dy.reportEvent({
      name: 'Purchase',
      properties: {
        dyType: 'purchase-v1',
        value: netPreis,
        uniqueTransactionId: action.meta.order.orderNumber,
        cart: action.payload.items.map((item) => ({
          productId: item.product?.sku || '',
          itemPrice:
            item.tracking?.packPriceNet ||
            item.tracking?.piecePriceNet ||
            item.unitPrice,
          quantity: item.amount
        })),
        currency: config.i18n.currency_ISO
      }
    })
  }
})

addRule({
  id: 'dy/SYNC_CART',
  target: `CART_UPDATE_ITEM`,
  output: '#dy-event',
  consequence: (action) => {
    dy.reportEvent({
      name: 'Sync Cart',
      properties: {
        dyType: 'sync-cart-v1',
        cart: action.cart.items.map((item) => ({
          productId: item.sku,
          itemPrice:
            item.tracking?.packPriceNet ||
            item.tracking?.piecePriceNet ||
            item.prices.piecePriceNet,
          quantity: item.quantity
        })),
        currency: config.i18n.currency_ISO
      }
    })
  }
})
addRule({
  id: 'dy/ScrollEvent',
  target: [`SCROLL25`, 'SCROLL50', 'SCROLL75'],
  output: '#dy-event',
  addUntil: function* (next, { context }) {
    yield next([LOCATION_CHANGED], () => {
      context.set('triggered', {
        Scrolldepth25: false,
        Scrolldepth50: false,
        Scrolldepth75: false
      })
    })
    return 'RECREATE_RULE'
  },
  consequence: (action, { context }) => {
    const events = context.get('triggered') || {}
    if (!events[action.payload]) {
      dy.reportEvent({
        name: action.payload,
        properties: {
          PageType: getPageInfo(window.location.pathname).pageType
        }
      })
    }
    events[action.payload] = true
  }
})
addRule({
  id: 'dy/COUPON',
  target: `PROMOTION_CODE_ADD`,
  output: '#dy-event',
  consequence: (action) => {
    const item: PromotionItem = action.cart.promotionItems.find(
      (items) => items.code === action.code
    ) || {
      code: action.code,
      cartItemId: '-',
      reduction: 0,
      priceDefinitionType: 'absolute',
      priceDefinitionPercentage: 0,
      priceDefinitionAbsolute: 0
    }

    dy.reportEvent({
      name: 'coupon',
      properties: {
        name: 'coupon',
        properties: {
          code: item.code,
          type: item.cartItemId,
          amount: item.reduction,
          locale: config.locale
        }
      }
    })
  }
})

addRule({
  id: 'dy/REMOVE_FROM_CART',
  target: [`CART_REMOVE_ITEM`, 'CART_ITEM_DECREASE_QUANTITY'],
  output: '#dy-event',
  concurrency: 'LAST',
  consequence: async (action) => {
    const quantity =
      action.type === 'CART_ITEM_DECREASE_QUANTITY'
        ? action.quantity
        : action.item.quantity

    dy.reportEvent({
      name: 'Remove from Cart',
      properties: {
        dyType: 'remove-from-cart-v1',
        value: action.item.prices.piecePriceNet * quantity,
        quantity: quantity,
        productId: action.item.sku,
        cart: action.cart.items.map((item) => ({
          productId: item.sku,
          itemPrice: item.prices.piecePriceNet,
          quantity: item.quantity
        })),
        currency: config.i18n.currency_ISO
      }
    })

    dy.reportEvent({
      name: 'Sync Cart',
      properties: {
        dyType: 'sync-cart-v1',
        cart: action.cart.items.map((item) => ({
          productId: item.sku,
          itemPrice:
            item.tracking?.packPriceNet ||
            item.tracking?.piecePriceNet ||
            item.prices.piecePriceNet,
          quantity: item.quantity
        })),
        currency: config.i18n.currency_ISO
      }
    })
  }
})

addRule({
  id: 'dy/ADD_TO_CART',
  target: [`CART_ADD_ITEM`, `CART_ITEM_INCREASE_QUANTITY`],
  output: '#dl-event',
  consequence: async (action) => {
    const cartItem = action.cart.items.find(
      (item) => item.cartItemId === action.cartItemId
    )

    const quantity =
      action.type === 'CART_ITEM_INCREASE_QUANTITY'
        ? action.quantity
        : cartItem?.quantity || 1

    if (cartItem) {
      dy.reportEvent({
        name: 'Add to Cart',
        properties: {
          dyType: 'add-to-cart-v1',
          value:
            Math.round(
              (cartItem.prices.packPriceNet as number) * quantity * 100
            ) / 100,

          productId: cartItem.sku,
          quantity: quantity,
          currency: config.i18n.currency_ISO,
          cart: action.cart.items
            .map((item) => ({
              productId: item.sku,
              itemPrice:
                item.tracking?.packPriceNet ||
                item.tracking?.piecePriceNet ||
                item.prices.packPriceNet,
              quantity: item.quantity
            }))
            .reverse()
        }
      })
    }
    if (action.type === 'CART_ADD_ITEM') {
      dy.reportEvent({
        name: 'Sync Cart',
        properties: {
          dyType: 'sync-cart-v1',
          cart: action.cart.items.map((item) => ({
            productId: item.sku,
            itemPrice:
              item.tracking?.packPriceNet ||
              item.tracking?.piecePriceNet ||
              item.prices.piecePriceNet,
            quantity: item.quantity
          })),
          currency: config.i18n.currency_ISO
        }
      })
    }
  }
})

addRule({
  id: 'dy/CHANNEL_SWITCH',
  target: 'SET_CHANNEL',
  output: '#dy-event',
  consequence: async (action) => {
    manager.updateContext(() => ({ isChannelSet: true }))
    dy.reportEvent({
      name: 'Customer',
      properties: {
        Type: action.channel.toUpperCase() as ChannelType
      }
    })
  }
})

addRule({
  id: 'dy/KEYWORD_SEARCH',
  target: SET_SEARCH_VALUE,
  output: '#dy-event',
  debounce: 4000,
  consequence: (action) => {
    if (action.payload.trim().length)
      dy.reportEvent({
        name: 'Keyword Search',
        properties: {
          dyType: 'keyword-search-v1',
          keywords: action.payload
        }
      })
  }
})

addRule({
  id: 'dy/PAGE_VISIT',
  target: LOCATION_CHANGED,
  output: '#dl-event',
  consequence: async (action, { addRule }) => {
    const pageInfo = getPageInfo(action.payload.pathname)
    switch (pageInfo.pageType) {
      case 'Series':
        addRule('waitForSeriesName')
        break
      case 'Tag':
      case 'Category':
        addRule('waitForPageContext')
        break
      case 'Product':
        addRule('waitForPDP')
        break
      case 'Cart':
        addRule('waitForCart')
        break
      case 'Home':
        {
          const isChannelSet = manager._unsaveGetContext().isChannelSet
          if (config.features.b2cHomePage && !isChannelSet) {
            addRule('waitForChannel')
          } else {
            dy.reportPageVisit({
              type: 'HOMEPAGE',
              data: [],
              location: window.location.href,
              locale: config.i18n.locale.replace('-', '_')
            })
          }
        }
        break
      default:
        dy.reportPageVisit({
          type: 'OTHER',
          data: [],
          location: window.location.href,
          locale: config.i18n.locale.replace('-', '_')
        })
    }
  },
  subRules: {
    waitForSeriesName: {
      target: SERIES_FETCH_SUCCESS,
      output: '#dy-event',
      addOnce: true,
      consequence: () => {
        dy.reportPageVisit({
          type: 'OTHER',
          data: [],
          location: window.location.href,
          locale: config.i18n.locale.replace('-', '_')
        })
      }
    },
    waitForChannel: {
      target: 'SET_CHANNEL',
      output: '#dy-event',
      concurancy: 'FIRST',
      consequence: (action) => {
        manager.updateContext(() => ({ isChannelSet: true }))
        if (action.channel === 'b2c') {
          manager.bufferClear()
        } else {
          dy.reportPageVisit({
            type: 'HOMEPAGE',
            data: [],
            location: window.location.href,
            locale: config.i18n.locale.replace('-', '_')
          })
        }
      }
    },
    waitForPageContext: {
      target: LISTING_PAGE_CONTEXT,
      output: '#dy-event',
      addOnce: true,
      consequence: (action: ReturnType<typeof listingPageContext>) => {
        dy.reportPageVisit({
          type: 'CATEGORY',
          data: action.payload.dyPath.split('|'),
          location: window.location.href,
          locale: config.i18n.locale.replace('-', '_')
        })
      }
    },
    waitForPDP: {
      target: PDP_FETCH_SUCCESS,
      output: '#dy-event',
      debounce: 50,
      addOnce: true,
      consequence: (_, { getState }) => {
        const state = getState()
        const product = getDisplayVariant(state.productDetail)

        if (product) {
          dy.reportPageVisit({
            type: 'PRODUCT',
            data: [product.sku],
            location: window.location.href,
            locale: config.i18n.locale.replace('-', '_')
          })
        }
      }
    },
    waitForCart: {
      target: 'cart/PAGE_VIEW',
      output: '#dy-event',
      addOnce: true,
      consequence: (action: ReturnType<typeof cartPageView>) => {
        const cart = action.payload.cart.items
        dy.reportPageVisit({
          type: 'CART',
          data: cart.map((item) => item.sku),
          location: window.location.href,
          locale: config.i18n.locale.replace('-', '_')
        })
      }
    }
  }
})

addRule({
  id: 'dy/REPORT_WIDGET_CLICK',
  target: 'ProductWidget/WIDGET_CLICK',
  output: '#dy-event',
  condition: (action) => Boolean(action.meta.dyContext),
  consequence: (action) => {
    if (action.meta.dyContext?.type === 'CLICK') {
      dy.reportEngagement({
        type: 'CLICK',
        decisionId: action.meta.dyContext?.decisionId || ''
      })
    } else {
      dy.reportEngagement({
        type: 'SLOT_CLICK',
        slotId: action.meta.dyContext?.slotId || ''
      })
    }
  }
})

// extra widget color click rule, because color img click does prevent widget click
addRule({
  id: 'dy/REPORT_WIDGET_COLOR_CLICK',
  target: 'ProductWidget/COLOR_CLICK',
  output: '#dy-event',
  condition: (action) => Boolean(action.meta.dyContext),
  consequence: (action) => {
    if (action.meta.dyContext?.type === 'CLICK') {
      dy.reportEngagement({
        type: 'CLICK',
        decisionId: action.meta.dyContext?.decisionId || ''
      })
    } else {
      dy.reportEngagement({
        type: 'SLOT_CLICK',
        slotId: action.meta.dyContext?.slotId || ''
      })
    }
  }
})

addRule({
  id: 'dy/REPORT_ENGAGEMENT_CLICK',
  target: 'Personalization/CLICK',
  output: '#dy-event',
  consequence: (action) => {
    dy.reportEngagement({
      type: 'CLICK',
      decisionId: action.payload.decisionId
    })
  }
})

addRule({
  id: 'dy/COPY_CODE_TO_CLIPBOARD',
  target: 'NotificationBar/COPY_CODE_TO_CLIPBOARD',
  output: '#dy-event',
  consequence: async (action) => {
    dy.reportEvent({
      name: 'Copied Code',
      properties: {
        Value: action.payload.code,
        Module: 'notificationbar'
      }
    })
  }
})

addRule({
  id: 'dy/404',
  target: 'ERROR_404',
  output: '#dy-event',
  consequence: async () => {
    dy.reportPageVisit({
      type: 'OTHER',
      data: [],
      location: window.location.href,
      locale: config.i18n.locale.replace('-', '_')
    })
  }
})
