import { GridContext } from '@kaminrunde/fireside-utils'
import imageToBase64 from '../utils/imageToBase64'
import config from '../config'
import sizeOf from 'buffer-image-size'
import { dataUriToBuffer } from 'data-uri-to-buffer'
import whitePixel from './whitePixel'
import { MS, ms } from './media-size'

type OptImgWidth =
  | { type: 'percent'; value: number }
  | { type: 'pixel'; value: number }

export type OptImg = {
  src: string
  sizes: { w: number; h: number }
  widths: Record<MS, OptImgWidth>
  manipulated: boolean
  base64: string
  ratio: number
}

const smallerImgSrc = (src: string) => {
  const params = `w_40,f_auto,dpr_1`
  return (
    config.modules.cloudinary.endpoint +
    'image/fetch/' +
    params +
    '/' +
    encodeURIComponent(src)
  )
}

export async function createOptImg(
  src: string,
  ctx: GridContext,
  modulator: Partial<Record<MS, number | string>> = {}
): Promise<OptImg> {
  src = src.trim()

  if (modulator.XS) {
    if (!modulator.SM) modulator.SM = modulator.XS
    if (!modulator.MD) modulator.MD = modulator.SM
    if (!modulator.LG) modulator.LG = modulator.MD
    if (!modulator.XL) modulator.XL = modulator.LG
  }

  // eslint-disable-next-line prefer-const
  let { base64, w, h } = await imageToBase64(smallerImgSrc(src))
  if (base64 === undefined || base64.length === 0) {
    base64 = whitePixel
  }

  const parsed = dataUriToBuffer(base64)
  const size = sizeOf(Buffer.from(parsed.buffer))
  const widths: Record<MS, OptImgWidth> = {
    XS: { type: 'percent', value: 1 },
    SM: { type: 'percent', value: 1 },
    MD: { type: 'percent', value: 1 },
    LG: { type: 'percent', value: 1 },
    XL: { type: 'percent', value: 1 }
  }

  let manipulated = false
  let ctxMs = ctx.byMediaSize['XS']
  const orderedMs: MS[] = ['XS', 'SM', 'MD', 'LG', 'XL']

  for (const key of orderedMs) {
    ctxMs = ctx.byMediaSize[key] || ctxMs
    if (!ctxMs) continue
    const size = ctxMs.colStretch / ctxMs.totalCols
    const value = modulator[key]
    if (typeof value === 'number') {
      widths[key] = { type: 'percent', value: size * value }
    } else if (value && value.endsWith('px')) {
      widths[key] = { type: 'pixel', value: parseInt(value.slice(0, -2)) }
    } else if (value && value.endsWith('%')) {
      widths[key] = { type: 'percent', value: parseFloat(value.slice(0, -1)) }
    }
  }

  if (src.match(/[wWhHqQxXyYcC]_/)) {
    manipulated = true
  }

  if (!src.includes('/image/upload/')) {
    manipulated = true
  }

  if (!size.width) throw new Error('could not resolve dimensions')

  return {
    src: src,
    sizes: { w, h },
    widths: widths,
    manipulated,
    base64,
    ratio: (size.width || 1) / (size.height || 1)
  }
}

const msWidth = {
  XS: ms['SM'],
  SM: ms['MD'],
  MD: ms['LG'],
  LG: ms['XL'],
  XL: 2000
}

export function optImgToSrc(
  optImg: OptImg,
  mediaSize: MS,
  bgRef: HTMLElement | HTMLImageElement,
  maxWidth: number
): string {
  const isBg = bgRef.tagName !== 'IMG'
  let windowWidth = msWidth[mediaSize]
  // optimize for lighthouse score. lighhouse calcualtes with a device with of 412px
  if (document.body.clientWidth <= 412) {
    windowWidth = 412
  }
  if (windowWidth > maxWidth) windowWidth = maxWidth
  const size = optImg.widths[mediaSize]
  let dpr = window.devicePixelRatio
  if (dpr > 2) dpr = 2
  let imgWidth =
    size.type === 'percent' ? Math.ceil(windowWidth * size.value) : size.value

  if (isBg) {
    const rect = bgRef.getBoundingClientRect()
    const rectRatio = rect.width / rect.height

    // we only want to scale images that overlap on the x-axis
    if (rectRatio < optImg.ratio) {
      const scale = optImg.ratio / rectRatio
      imgWidth =
        size.type === 'percent'
          ? Math.ceil(windowWidth * size.value * scale)
          : size.value
    }
  }

  const params = `w_${imgWidth},f_auto,dpr_${dpr},q_80`
  const src = optImg.manipulated
    ? config.modules.cloudinary.endpoint +
      'image/fetch/' +
      params +
      '/' +
      encodeURIComponent(optImg.src)
    : optImg.src.replace('/upload/', '/upload/' + params + '/')
  return src
}
