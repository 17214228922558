import { dispatchEvent } from 'redux-ruleset'
import * as at from 'modules/ui/const'

export default function registerLeavePage() {
  if (typeof document !== 'undefined') {
    const html = document.querySelector('html')

    const dispatch = () => {
      const isIntentLayerExecuted =
        sessionStorage.getItem('intentLayerExecuted') === 'true'
      if (!isIntentLayerExecuted)
        dispatchEvent({
          type: at.SET_INTENT_VISIBLE,
          payload: { visible: true, source: 'exit_intent' }
        })
    }

    if (html && sessionStorage.getItem('intentLayerExecuted') !== 'true') {
      html.removeEventListener('mouseleave', dispatch, false)
      html.addEventListener('mouseleave', dispatch)
    }
  }
}
