import createAlgoliaHelper from '../createAlgoliaHelper'
import config from '../../config'
import * as t from './types'
import attributesToRetrieve from './attributes-to-retrieve'

/** @firescoutMockFn productListFetcher.bySku */
export default function bySku(
  skus: string[],
  maxHits = 10,
  keepSorting = true
) {
  return async (page: number): Promise<t.Result> => {
    const helper = await createAlgoliaHelper(config.index.products, {
      hitsPerPage: maxHits < 10 ? maxHits : skus.length,
      facets: ['active', 'sellable'],
      disjunctiveFacets: ['sku'],
      attributesToRetrieve: attributesToRetrieve
    })
    skus.forEach((sku) => helper.addDisjunctiveFacetRefinement('sku', sku))

    helper.addFacetRefinement('active', 'true')
    helper.addFacetRefinement('sellable', 'true')

    helper.setPage(page)

    const result = await helper.searchOnce({})

    const hitDict: Record<string, t.Product> = {}
    for (const hit of result.content.hits) hitDict[hit.sku] = hit

    let products = skus.map((sku) => hitDict[sku]).filter(Boolean)

    if (keepSorting) {
      const productDict: Record<string, t.Product> = {}
      for (const p of products) productDict[p.sku] = p
      products = skus.map((sku) => productDict[sku]).filter(Boolean)
    }

    //Returns channelActive default true for b2b/b2c to ensure they can't be null
    products.forEach((item, i) => {
      products[i] = {
        ...item,
        channelActive: {
          b2c: item.channelActive?.b2c ?? true,
          b2b: item.channelActive?.b2b ?? true
        }
      }
    })

    // TODO: Remove as soon as the backend returns null instead of 'unknown'
    products.forEach((item) => {
      if (item.brand === 'unknown') item.brand = undefined
    })

    return {
      data: products,
      nbHits: maxHits < result.content.nbHits ? maxHits : result.content.nbHits,
      finished: !(result.content.nbPages > page)
    }
  }
}
